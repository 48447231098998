@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.btn {
		@apply flex justify-center items-center font-bold rounded-full opacity-75 hover:opacity-100 hover:shadow-md text-base outline-none;
	}

	.isNotActiveStyle {
		@apply flex items-center px-5 gap-3 dark:text-gray-500 dark:hover:text-black text-neutral-400 hover:text-white transition-all duration-200 ease-in-out capitalize;
	}

	.isActiveStyle {
		@apply flex items-center px-5 gap-3 font-extrabold border-r-2 dark:border-black dark:text-slate-900 text-white border-white transition-all duration-200 ease-in-out capitalize;
	}

	.activeBtnStyles {
		@apply bg-red-500 text-white font-bold p-2 rounded-full w-20 outline-none;
	}

	.notActiveBtnStyles {
		@apply bg-white mr-4 text-black font-bold p-2 rounded-full w-20 outline-none;
	}
}

body {
	margin: 0;
	font-family: 'Lato', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
